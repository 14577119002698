<template>
  <div class="container">
    <div class="row pt-4 pb-4 text-center text-md-left">
      <div class="col-12 pb-3">
        <h2 class="text-white">Contacts</h2>
      </div>
      <div class="col-12 d-none d-md-block">
        <div class="row">
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1 hidden">
                <h6 class="text-up">company</h6>
              </div>
              <div class="col-12">
                <h5 class="text-up company">
                  <span>CONSULBROKERS</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">e-mail</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="mailto:consulbrokers@uim.sport">consulbrokers@uim.sport</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">phone</h6>
              </div>
              <div class="col-12">
                <h5 class="text-white">
                  <a href="tel:+377 92 05 25 22">+39 02 70102458</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">address</h6>
              </div>
              <div class="col-12">
                <h5 class="text-white">
                  <a href="https://maps.google.com/?q=Corso di Porta Nuova 16 - 20129 Milano" target="_blank" rel="nofollow">
                    Corso di Porta Nuova 16 - 20129 Milano
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12d-md-none d-lg-none">
        <div class="row">
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-3">
                <h5 class="text-up company">
                  <span>UIM</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">e-mail</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="mailto:uim@uim.sport">uim@uim.sport</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">phone</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="tel:+377 92 05 25 22">+377 92 05 25 22</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">fax</h6>
              </div>
              <div class="col-12">
                <h5 class="text-white">
                  <a href="tel:+377 92 05 25 22">+377 92 05 25 22</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">address</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="https://maps.google.com/?q=1, Avenue Des Castelans 98000 Monaco" target="_blank" rel="nofollow">
                    1, Avenue Des Castelans - 98000 Monaco
                  </a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-3 pt-3">
                <h5 class="text-up company">
                  <span>CONSULBROKERS</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">e-mail</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="mailto:uim@consulbrokers.it">uim@consulbrokers.it</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">phone</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="tel:+377 92 05 25 22">+39 02 70102458</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">address</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="https://maps.google.com/?q=Corso di Porta Nuova 16 - 20129 Milano" target="_blank" rel="nofollow">
                    Corso di Porta Nuova 16 - 20129 Milano
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
