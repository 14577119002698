<template>
  <div class="box">
    <div class="b box-1" v-if="!item.active">
      <div class="row text-center">
        <div class="col-4 p-3 d-flex align-items-center justify-content-center"
             :class="[ item.index === 0 ? 'selected' : 'tab']" @click="select(0)">
          <h6 class="title m-0">Third Part Liability (O.L.)</h6>
        </div>
        <div class="col-4 p-3 d-flex align-items-center justify-content-center"
             :class="[ item.index === 1 ? 'selected' : 'tab']" @click="select(1)">
          <h6 class="title m-0">Medical Expenses Refund</h6>
        </div>
        <div class="col-4 p-3 d-flex align-items-center justify-content-center"
             :class="[ item.index === 2 ? 'selected' : 'tab']" @click="select(2)">
          <h6 class="title m-0">Accident Risk Policy</h6>
        </div>
      </div>
    </div>
    <div class="b box-2">
      <div class="row h-100 no-gutters">
        <div class="col-12" v-if="item.index === 0">
          <div v-if="!item.active" class="row h-100">
            <div class="col-12">
              <h4 class="pt-4 pr-4 pl-4 pb-0 m-0">
                Third Party Liability Policy for event organization</h4>
            </div>
            <div class="col-12">
              <h5 class="p-4 m-0 text">
                The TPL policy is designed to cover organizers in the event
                that a third party suffers material
                damage or accidental injuries; it covers loss of or damage to
                property occurring in connection with
                or arising from the insured event as well as death or personal
                injury of any person and/or loss or
                damage to property resulting from products manufactured,
                constructed, erected, installed,
                treated, modified, maintained, repaired, sold, handled,
                supplied or distributed by the Insured
                (including any container other than a vehicle).
              </h5>
            </div>
            <div class="col-12 pr-4 pl-4">
              <div class="row h-100 no-gutters align-items-end">
                <div class="col-12">
                  <div class="pr-3 pl-3">
                    <div class="button mb-1" @click="action">
                      <h5 class="m-0">Start your quotation</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <h4 class="pt-2 pr-4 pl-4 pb-0 m-0">
                Third Party Liability Policy for event organization
              </h4>
            </div>
            <div class="col-12">
              <form @submit.prevent="send(data.first,
              'Third Party Liability Policy for event organization')"
                    class="row pr-4 pl-4 pt-1 pb-4">
                <div class="col-12 col-md-6 pt-3">
                  <app-input v-model="data.first.organizer"
                             placeholder="Event organizer"
                             required>
                  </app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input v-model="data.first.categories"
                             placeholder="Race Categories"
                             required>
                  </app-input>
                </div>
                <div class="col-12 pt-3">
                  <app-input
                    v-model="data.first.venue"
                    placeholder="Race venue - Event place (City and Nation)"
                    required
                  ></app-input>
                </div>
                <div class="col-12 pt-3">
                  <app-input
                    v-model="data.first.involved"
                    placeholder="Estimated number of people involved in organization"
                    required
                  ></app-input>
                </div>
                <div class="col-6 pt-3">
                  <app-input
                    v-model="data.first.email"
                    placeholder="E-Mail address of contact"
                    required>
                  </app-input>
                </div>
                <div class="col-6 pt-3">
                  <app-input
                    v-model="data.first.phone"
                    placeholder="Mobile phone of contact"
                    required>
                  </app-input>
                </div>
                <div class="col-12 pt-3">
                  <app-text-area
                    v-model="data.first.note"
                    placeholder="Note">
                  </app-text-area>
                </div>
                <div class="col-12 pt-3" v-if="item.result != null">
                  <div class="center">
                      <span class="err" v-if="!item.result">
                        An error has occurred, try again</span>
                    <span class="success" v-else>
                        Your request has been sent</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <div class="button-reverse pt-3" @click="action">
                    <h5 class="m-0">return back</h5>
                  </div>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <button class="button pt-3" type="submit">
                    <h5 v-if="!item.loading" class="m-0">Send a request</h5>
                    <div class="spinner-grow" style="width: 1rem; height: 1rem;"
                         v-else role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 h-100" v-if="item.index === 2">
          <div v-if="!item.active" class="row h-100">
            <div class="col-12">
              <h4 class="pt-4 pr-4 pl-4 pb-0 m-0">Accident Risk policy</h4>
            </div>
            <div class="col-12">
              <h5 class="p-4 m-0 text">
                The Accident Risk Policy covers drivers/riders in case of accidents
                suffered during the period of validity of the policy while carrying out:
                <ul class="pt-3">
                  <li class="pb-3">
                    the main professional activity declared, as well as any other
                    undeclared professional activity
                    which, if known by the Insurers beforehand,
                    would not have determined any premium increase.
                  </li>
                  <li>
                    any other activity that is not of a professional nature
                  </li>
                </ul>
              </h5>
            </div>
            <div class="col-12 pr-4 pl-4">
              <div class="row h-100 no-gutters align-items-end">
                <div class="col-12">
                  <div class="pr-3 pl-3">
                    <div class="button mb-1" @click="action">
                      <h5 class="m-0">Start your quotation</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <h4 class="pt-2 pr-4 pl-4 pb-0 m-0">Accident Risk policy</h4>
            </div>
            <div class="col-12">
              <form @submit.prevent="send(data.second,
                'Accident Risk policy')" class="row pr-4 pl-4 pt-1 pb-4">
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="First name"
                             v-model="data.second.firstName" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Last name"
                             v-model="data.second.lastName" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Fiscal code"
                             v-model="data.second.fiscalCode" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input
                    v-model="data.second.birthDate"
                    onfocus="this.type='date'"
                    placeholder="Birth date"
                    required
                  ></app-input>
                </div>
                <div class="col-6 pt-3">
                  <app-input placeholder="Nationality"
                             v-model="data.second.nationality" required></app-input>
                </div>
                <div class="col-12 pt-3">
                  <app-input placeholder="Address"
                             v-model="data.second.address" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="E-Mail address"
                             v-model="data.second.email" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Mobile phone"
                             v-model="data.second.phone" required></app-input>
                </div>
                <div class="col-12 pt-3">
                  <app-text-area placeholder="Note"
                                 v-model="data.second.note"></app-text-area>
                </div>
                <div class="col-12 pt-3" v-if="item.result != null">
                  <div class="center">
                      <span class="err" v-if="!item.result">
                        An error has occurred, try again</span>
                    <span class="success" v-else>
                        Your request has been sent</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <div class="button-reverse pt-3" @click="action">
                    <h5 class="m-0">return back</h5>
                  </div>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <button class="button pt-3" type="submit">
                    <h5 class="m-0" v-if="!item.loading">Send a request</h5>
                    <div class="spinner-grow" style="width: 1rem; height: 1rem;"
                         v-else role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 h-100" v-if="item.index === 1">
          <div v-if="!item.active" class="row h-100">
            <div class="col-12">
              <h4 class="pt-4 pr-4 pl-4 pb-0 m-0">Medical Expenses Refund</h4>
            </div>
            <div class="col-12">
              <h5 class="p-4 m-0 text">
                The Medical Expenses Refund covers the drivers/riders,
                staff as well as the organizers for all
                medical, pharmaceutical and hospital expenses incurred
                in the event due to illness or accident.
                The Insurance Company makes direct payment to hospitals
                and recourses to reimbursement, for
                all other medical and pharmaceutical costs.
                Search and rescue costs are also included. The
                guarantees included in the policy can be extended to additional accessories.
              </h5>
            </div>
            <div class="col-12 pr-4 pl-4">
              <div class="row h-100 no-gutters align-items-end">
                <div class="col-12">
                  <div class="pr-3 pl-3">
                <div class="button mb-1" @click="action">
                  <h5 class="m-0">Start your quotation</h5>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <h4 class="pt-2 pr-4 pl-4 pb-0 m-0">Medical Expenses Refund</h4>
            </div>
            <div class="col-12">
              <form @submit.prevent="send(data.third,
                'Medical Expenses Refund')" class="row pr-4 pl-4 pt-1 pb-4">
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="First Name"
                             v-model="data.third.firstName" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Last Name"
                             v-model="data.third.lastName" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Fiscal code"
                             v-model="data.third.fiscalCode" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input
                    v-model="data.third.birthDate"
                    onfocus="this.type='date'"
                    placeholder="Birth Date"
                    required
                  ></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Nationality"
                             v-model="data.third.nationality" required></app-input>
                </div>
                <div class="col-12 pt-3">
                  <app-input placeholder="Address"
                             v-model="data.third.address" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input type="email" placeholder="E-Mail"
                             v-model="data.third.email" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Mobile phone" type="tel"
                             v-model="data.third.phone" required></app-input>
                </div>
                <div class="col-12 pt-3">
                  <app-text-area placeholder="Note"
                                 v-model="data.third.note"></app-text-area>
                </div>
                <div class="col-12 pt-3" v-if="item.result != null">
                  <div class="center">
                      <span class="err" v-if="!item.result">
                        An error has occurred, try again</span>
                    <span class="success" v-else>
                        Your request has been sent</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <div class="button-reverse pt-3" @click="action">
                    <h5 class="m-0">return back</h5>
                  </div>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <button type="submit" class="button pt-3">
                    <h5 class="m-0" v-if="!item.loading">Send a request</h5>
                    <div class="spinner-grow" style="width: 1rem; height: 1rem;"
                         v-else role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import { ref, reactive } from 'vue';
import AppInput from '@/components/input.vue';
import AppTextArea from '@/components/textarea.vue';

export default {
  name: 'Box',
  components: {
    AppInput,
    AppTextArea,
  },
  setup() {
    const data = reactive({
      first: {
        organizer: null,
        categories: null,
        venue: null,
        involved: null,
        email: null,
        phone: null,
        note: null,
      },
      second: {
        firstName: null,
        lastName: null,
        fiscalCode: null,
        birthDate: null,
        nationality: null,
        address: null,
        email: null,
        phone: null,
        note: null,
      },
      third: {
        firstName: null,
        lastName: null,
        fiscalCode: null,
        birthDate: null,
        nationality: null,
        address: null,
        email: null,
        phone: null,
        note: null,
      },
    });
    const item = reactive({
      index: 0,
      action: false,
      loading: false,
      result: null,
    });
    const book = ref({ title: 'Vue 3 Guide' });
    function select(index) {
      item.index = index;
    }
    function action() {
      item.active = !item.active;
    }
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    async function send(input, product) {
      item.result = null;
      item.loading = true;
      await sleep(1000);
      /* eslint-disable no-param-reassign */
      input.product = product;
      input.created_at = firebase.firestore.Timestamp.fromDate(new Date());
      input.updated_at = firebase.firestore.Timestamp.fromDate(new Date());
      firebase.firestore().collection('requests').add(input)
        .then((res) => {
          console.log(res);
          item.loading = false;
          item.result = true;
        })
        .catch((err) => {
          console.log(err);
          item.result = false;
          item.loading = false;
        });
    }
    // expose to template
    return {
      select,
      action,
      send,
      data,
      item,
      book,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/style.scss";
  .text{
    text-align: justify;
    line-height: 1.35rem;
  }
  .box {
    display: flex;
    flex-direction: column;
  }

  .b {
    padding: 16px;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
  }

  .box-1 {
    height: 60px;
    justify-content: center;
  }

  .box-2 {
    flex: 1;
    justify-content: start;
  }

</style>
