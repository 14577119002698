<template>
  <div class="container">
    <div class="row align-items-stretch">
      <div class="col-12 col-md-6 d-flex align-items-center pb-md-0 pb-5">
        <div class="row">
          <div class="col-12">
            <h1 class="text-white mb-4">
              Insurance Cover
            </h1>
          </div>
          <div class="col-12">
            <div class="bb"></div>
          </div>
          <div class="col-12">
            <h4 class="text-white pt-5">
              The UIM, through its partnership with Consulbrokers Spa,
              provides interested persons or entities access to tailor-made
              insurance cover:
            </h4>
          </div>
          <div class="col-12">
            <h5 class="text-white pt-4 grey">
              <ul>
                <li>Third Party Liability covers Organizers in the event of accident
                  / theft of persons / properties of any person participating in a race.
                </li>
                <li>
                  Medical Expenses Refund reimburses or pays directly
                  medical expenses to drivers,
                  staff and organizers.
                </li>
                <li>
                  Accident Risk Policy covers professional and /
                  or non-professional accidents
                </li>
              </ul>
              Interested? Click the link to request your personalized quotation!
            </h5>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <app-box/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  h4{
    color: #A7ADB1 !important;
  }
  .bb{
    height: 1px;
    width: 150px;
    border-bottom: 1px solid #FCFCFC;
  }
  ul{
    padding-left: 16px;
  }
</style>

<script>
import AppBox from '@/components/box.vue';

export default {
  name: 'Home',
  components: {
    AppBox,
  },
};
</script>
