<template>
  <div class="layout">
    <div class="container-fluid">
      <div class="row justify-content-center min-vh-100">
        <div class="col-12 bg-red">
          <div class="d-flex flex-column h-100">
            <app-header></app-header>
            <div class="row justify-content-center align-items-center flex-grow-1">
              <div class="col-12">
                <slot/>
              </div>
            </div>
            <app-footer></app-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/header.vue';
import AppFooter from '@/components/footer.vue';

export default {
  name: 'Home',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style lang="scss" scoped>
  .layout {
    min-height: 100%;
    background: url("../assets/bg.png") center no-repeat;
    background-size: cover;
  }
</style>
