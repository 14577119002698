<template>
    <input
      v-bind="$attrs"
      :type="type"
      :required="required"
      :placeholder="placeholder + (required ? '*' : '')"
      :value="modelValue"
      class="action-btn"
      @input="$emit('update:modelValue', $event.target.value)"
    >
</template>

<script>
export default {
  name: 'Input',
  props: {
    modelValue: {
      default: '',
    },
    focus: {
      type: Function,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
  .action-btn{
    width: 100%;
    padding: 0 16px;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    height: 42px;
    border: 1px solid #CCCCCC;
    background: #FCFCFC;
    box-shadow: none;
    outline: none;
  }
</style>
