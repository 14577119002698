<template>
  <div class="container">
    <div class="row pt-4 pb-4">
      <div class="col-12 col-md-6 text-center text-md-left">
        <img alt="UIM Logo" src="@/assets/logo.png">
      </div>
      <div class="
        pt-md-0
        pt-4
        col-12 col-md-6
        text-center
        text-md-right
        d-flex align-items-center
        justify-content-md-end
        justify-content-center"
      >
        <a href="http://www.consulbrokers.it/" title="Consulbrokers" rel="nofollow" target="_blank">
          <img alt="UIM Logo" src="@/assets/consulbrokers.png">
        </a>
      </div>
    </div>
  </div>
</template>
