<template>
  <textarea
    :placeholder="placeholder + (required ? '*' : '')"
    rows="3" cols="50"
    :required="required"
    class="action-txt"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  ></textarea>
</template>

<script>
/*
import { ref } from 'vue';
*/

export default {
  name: 'Input',
  props: {
    modelValue: {
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    cols: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
.action-txt{
  width: 100%;
  font-size: 14px;
  text-align: left;
  border: 1px solid #CCCCCC;
  background: #FCFCFC;
  resize: none;
  outline: none;
  padding: 16px;
  border-radius: 4px;
  box-shadow: none;
}
</style>
