import { createApp } from 'vue';
import firebase from 'firebase';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css/normalize.css';

const config = {
  apiKey: 'AIzaSyB2V9X_o950DQDRyRINp1EKOhV3zjYUd84',
  authDomain: 'cb-uim.firebaseapp.com',
  databaseURL: 'https://cb-uim.firebaseio.com',
  projectId: 'cb-uim',
  storageBucket: 'cb-uim.appspot.com',
  messagingSenderId: '884530879047',
  appId: '1:884530879047:web:45de49bb3583fdb2506f62',
  measurementId: 'G-W0414HCWG6',
};

firebase.initializeApp(config);

createApp(App).use(store).use(router).mount('#app');
